module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"2025 OCP EMEA Summit","short_name":"2025 OCP EMEA Summit","start_url":"/","display":"minimal-ui","icon":"src/content/site-settings/ocp25e-fnv-gen-favicon.png","include_favicon":true,"description":"From Ideas to Impact. \nThe OCP EMEA Summit serves as a platform where global technical leaders come together to tackle the issues related to data center sustainability, energy efficiency and heat reuse in the region. The focus is on exploring how innovations from hyperscale data center operators can contribute to solving these challenges. The OCP EMEA Summit also highlights deployments of specific OCP-recognized data center equipment in the EMEA region.","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"67c5ad823deda5afa1145986eb2ed7cf"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/node_modules/@openeventkit/event-site/src/cms/cms.js","manualInit":true,"enableIdentityWidget":false},
    },{
      plugin: require('../node_modules/@openeventkit/event-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
